@import 'functions';
@import 'variables';
@import 'mixins';

/* Nvd3 Chart */

.widget-chart-full-width {
    overflow: hidden;
    & .nv-chart {
        margin-left: -20px;
        & .nvd3-svg {
            height: 110% !important;
        }
    }
}


/* React Calendar */

.react-calendar {
    width: auto;
    min-width: 300px;
    border: none;
    font-family: inherit;
    background: none;
    padding: 5px;
    & .react-calendar__navigation {
        height: auto;
        margin-bottom: 0;
        min-height: 30px;
        & .react-calendar__navigation__label {
            font-weight: 700;
            font-size: 12px;
            padding: 0;
            @include border-radius($border-radius-lg);
            &:hover,
            &:focus {
                background: lighten($dark, 75%);
                outline: none;
            }
            &[disabled] {
                background: none;
                color: lighten($dark, 50%);
            }
        }
        & .react-calendar__navigation__arrow {
            color: transparent;
            position: relative;
            padding: 0;
            @include border-radius($border-radius-lg);
            @include fontawesome();
            &:hover,
            &:focus {
                background: lighten($dark, 75%);
                outline: none;
            }
            &:after {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                color: $dark;
                @include display-flex();
                @include flex-align(center);
                @include flex-justify-content(center);
            }
            &.react-calendar__navigation__prev2-button:after {
                content: '\f100';
            }
            &.react-calendar__navigation__prev-button:after {
                content: '\f104';
            }
            &.react-calendar__navigation__next2-button:after {
                content: '\f101';
            }
            &.react-calendar__navigation__next-button:after {
                content: '\f105';
            }
        }
    }
    & .react-calendar__month-view {
        & .react-calendar__month-view__weekdays {
            & .react-calendar__month-view__weekdays__weekday {
                min-height: 30px;
                @include display-flex();
                @include flex-align(center);
                @include flex-justify-content(center);
                & abbr {
                    font-size: 12px;
                    text-decoration: initial;
                    font-weight: bold;
                    text-transform: initial;
                }
            }
        }
        & .react-calendar__month-view__days {
            & .react-calendar__tile {
                height: auto;
                min-height: 30px;
                color: lighten($dark, 30%);
                font-weight: 600;
                @include border-radius($border-radius-lg);
                &:hover,
                &:focus {
                    background: lighten($dark, 75%);
                    color: $dark;
                    outline: none;
                }
                &.react-calendar__tile--active {
                    background: $form-component-active-bg;
                    color: $white;
                }
            }
        }
    }
    & .react-calendar__year-view,
    & .react-calendar__decade-view,
    & .react-calendar__century-view {
        & .react-calendar__tile {
            height: auto;
            min-height: 50px;
            color: lighten($dark, 30%);
            font-weight: 600;
            padding: 0;
            @include border-radius($border-radius-lg);
            &:hover,
            &:focus {
                background: lighten($dark, 75%);
                color: $dark;
                outline: none;
            }
            &.react-calendar__tile--active,
            &.react-calendar__tile--hasActive {
                background: $form-component-active-bg;
                color: $white;
            }
        }
    }
}


/* Perfect Scrollbar */

.ps {
    & .ps__rail-x {
        z-index: 10;
        &:focus,
        &:hover,
        &.ps--clicking {
            background: none;
        }
    }
    & .ps__rail-y {
        z-index: 10;
        width: 6px;
        &:focus,
        &:hover,
        &.ps--clicking {
            width: 6px !important;
            background: none;
            & .ps__thumb-y,
            &>.ps__thumb_y {
                width: 6px !important;
            }
        }
    }
}

.page-sidebar-minified {
    & .sidebar {
        & .ps {
            & .ps__rail-x,
            & .ps__rail-y {
                display: none !important;
            }
        }
    }
}


/* CodeMirror */

.CodeMirror {
    height: auto;
    font-size: 12px;
}


/* Countdown */

.countdown-row {
    clear: both;
    width: 100%;
    padding: 0px 2px;
    text-align: center;
}

.countdown-section {
    display: block;
    float: left;
    font-size: 75%;
    text-align: center;
    & .countdown-period {
        display: block;
    }
    & .countdown-descr {
        display: block;
        width: 100%;
    }
    .countdown-show1 & {
        width: 98%;
    }
    .countdown-show2 & {
        width: 48%;
    }
    .countdown-show3 & {
        width: 32.5%;
    }
    .countdown-show4 & {
        width: 24.5%;
    }
    .countdown-show5 & {
        width: 19.5%;
    }
    .countdown-show6 & {
        width: 16.25%;
    }
    .countdown-show7 & {
        width: 14%;
    }
}


/* Rdt Datepicker */

.rdt {
    & .rdtPicker {
        width: auto;
        right: 0;
        @include border-radius($border-radius-lg);
        @include box-shadow(0 0.5rem 1rem rgba($black, .175));
        & table {
            & thead {
                & tr {
                    & th {
                        &.rdtPrev,
                        &.rdtNext,
                        &.rdtSwitch {
                            line-height: 20px;
                            padding: 5px;
                            height: auto;
                            min-height: 30px;
                            border: none;
                            @include border-radius($border-radius);
                            &:hover,
                            &:focus {
                                background: lighten($dark, 75%);
                                & span:before {
                                    color: $dark;
                                }
                            }
                        }
                        &.rdtPrev,
                        &.rdtNext {
                            color: transparent;
                            width: 14.28%;
                            position: relative;
                            & span {
                                color: transparent;
                                position: relative;
                                display: block;
                                @include fontawesome();
                                @include border-radius($border-radius-lg);
                                &:before {
                                    color: lighten($dark, 30%);
                                    position: absolute;
                                    font-size: 14px;
                                    top: 0;
                                    left: 0;
                                    right: 0;
                                    bottom: 0;
                                    line-height: 1;
                                    @include display-flex();
                                    @include flex-align(center);
                                    @include flex-justify-content(center);
                                }
                                & i {
                                    display: none;
                                }
                            }
                        }
                        &.rdtPrev {
                            & span:before {
                                content: '\f104';
                            }
                        }
                        &.rdtNext {
                            & span:before {
                                content: '\f105';
                            }
                        }
                        &.rdtSwitch {
                            width: auto;
                        }
                        &.dow {
                            padding: 5px 0;
                        }
                    }
                }
            }
            & tfoot {
                border: none;
                & tr {
                    & td {
                        &.rdtTimeToggle {
                            border: none;
                            font-weight: 600;
                            color: lighten($dark, 30%);
                            text-shadow: none;
                            @include border-radius($border-radius-lg);
                            &:hover,
                            &:focus {
                                background: lighten($dark, 75%);
                                text-shadow: none;
                            }
                        }
                    }
                }
            }
        }
        & .rdtTime {
            min-width: 180px;
            & .rdtCounters {
                @include display-flex();
                @include flex-align(center);
                @include flex-justify-content(center);
                &>div {
                    float: none;
                }
                & .rdtCounter {
                    height: 110px;
                    width: 60px;
                    & .rdtBtn {
                        height: 35%;
                        color: transparent;
                        position: relative;
                        @include fontawesome();
                        @include border-radius($border-radius-lg);
                        &:before {
                            content: '\f106';
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            color: lighten($dark, 30%);
                            font-size: 18px;
                            @include display-flex();
                            @include flex-align(center);
                            @include flex-justify-content(center);
                        }
                        &:hover,
                        &:focus {
                            background: lighten($dark, 75%);
                        }
                    }
                    & .rdtCount {
                        height: 30%;
                        font-weight: 600;
                        color: lighten($dark, 15%);
                        font-size: 13px;
                        @include display-flex();
                        @include flex-align(center);
                        @include flex-justify-content(center);
                        &+.rdtBtn {
                            &:before {
                                content: '\f107';
                            }
                        }
                    }
                }
            }
        }
        & .rdtDays {
            min-width: 290px;
            & .rdtDay {
                font-weight: 600;
                color: lighten($dark, 15%);
                padding: 5px;
                line-height: 20px;
                @include border-radius($border-radius-lg);
                &:hover,
                &:focus,
                &.rdtSelected,
                &.rdtHighlighted,
                &.rdtRange {
                    background: lighten($dark, 75%);
                    text-shadow: none;
                }
                &.rdtToday {
                    background: lighten($dark, 70%) !important;
                    color: $dark !important;
                }
                &.rdtActive {
                    background: $form-component-active-bg !important;
                    color: $white !important;
                    text-shadow: none !important;
                }
                &.rdtOld {
                    color: lighten($dark, 60%);
                }
                &.rdtNew {
                    color: lighten($dark, 45%);
                }
                &.disabled {
                    color: lighten($dark, 60%);
                    &:hover,
                    &:focus {
                        background: none;
                    }
                }
            }
        }
        & .rdtMonths,
        & .rdtYears {
            min-width: 290px;
            & .rdtMonth,
            & .rdtYear {
                font-weight: 600;
                color: lighten($dark, 30%);
                text-shadow: none;
                @include border-radius($border-radius);
                &:hover,
                &:focus {
                    background: lighten($dark, 75%);
                }
                &.rdtFocused {
                    background: lighten($dark, 70%);
                    color: $dark;
                }
                &.rdtActive {
                    background: $form-component-active-bg !important;
                    color: $white;
                }
            }
        }
    }
}


/* React Datepicker */

.react-datepicker {
    border: none;
    display: block;
    font-family: inherit;
    .react-datepicker-popper & {
        padding: 5px;
        @include border-radius($border-radius-lg);
        @include box-shadow(0 0.5rem 1rem rgba($black, .175));
    }
    & .react-datepicker__triangle {
        display: none;
    }
    & .react-datepicker__navigation {
        color: transparent;
        padding: 5px;
        line-height: 20px;
        border: none;
        width: 30px;
        height: 30px;
        top: 0;
        text-indent: 0;
        @include border-radius($border-radius-lg);
        &:hover,
        &:focus {
            background: lighten($dark, 75%);
            outline: none;
            &:after {
                color: $dark;
            }
        }
        &:after {
            color: lighten($dark, 30%);
            position: absolute;
            font-size: 14px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            @include fontawesome();
            @include display-flex();
            @include flex-align(center);
            @include flex-justify-content(center);
        }
        &.react-datepicker__navigation--previous {
            &:after {
                content: '\f104';
            }
        }
        &.react-datepicker__navigation--next {
            &:after {
                content: '\f105';
            }
        }
    }
    & .react-datepicker__month-container {
        float: none;
        & .react-datepicker__header {
            background: none;
            border: none;
            padding: 0;
            & .react-datepicker__current-month {
                font-size: 12px;
                color: $dark;
                font-weight: 700;
                padding: 5px;
                line-height: 20px;
            }
            & .react-datepicker__day-names {
                @include display-flex();
                & .react-datepicker__day-name {
                    font-weight: 700;
                    color: $dark;
                    font-size: 12px;
                    padding: 5px;
                    line-height: 20px;
                    margin: 0;
                    @include flex(1);
                }
            }
        }
        & .react-datepicker__month {
            margin: 0;
            & .react-datepicker__week {
                @include display-flex();
                & .react-datepicker__day {
                    padding: 5px;
                    line-height: 20px;
                    font-weight: 600;
                    color: lighten($dark, 30%);
                    @include flex(1);
                    @include border-radius($border-radius-lg);
                    &:hover,
                    &:focus,
                    &.react-datepicker__day--today {
                        background: lighten($dark, 75%);
                        color: $dark;
                        outline: none;
                    }
                    &.react-datepicker__day--selected {
                        background: $form-component-active-bg;
                        color: $white;
                    }
                }
            }
        }
    }
}


/* React Tag Autocomplete */

.react-tags {
    min-height: 34px;
    border: 1px solid $border-color;
    padding: 5px 12px;
    @include border-radius($border-radius);
    @include display-flex();
    @include flex-wrap(wrap);
    @include transition(all .2s linear);
    .email-to & {
        border-left: none;
        border-right: none;
        border-top: none;
        padding-left: 50px;
        min-height: 43px;
        @include flex-align(center);
        @include border-radius(0);
        &.is-focused {
            @include box-shadow(none);
        }
    }
    &.is-focused {
        border-color: $input-focus-border-color;
        @include box-shadow(0 0 0 0.2rem rgba($blue, .25));
    }
    & .react-tags__selected {
        & .react-tags__selected-tag {
            background: $form-component-active-bg;
            color: $white;
            padding: 2px 25px 2px 10px;
            border: none;
            outline: none;
            font-weight: 600;
            font-size: 11px;
            margin: 2px;
            position: relative;
            @include border-radius($border-radius);
            &:after {
                content: '\f00d';
                position: absolute;
                right: 2px;
                top: 0;
                bottom: 0;
                width: 20px;
                font-size: 10px;
                @include fontawesome();
                @include display-flex();
                @include flex-align(center);
                @include flex-justify-content(center);
            }
        }
    }
    & .react-tags__search {
        position: relative;
        & .react-tags__search-input {
            & input {
                border: none;
                background: none;
                height: 20px;
                outline: none;
                margin: 2px 5px;
                font-weight: 600;
                color: $dark;
                @include box-shadow(none);
                @include placeholder(lighten($dark, 30%));
            }
        }
        & .react-tags__suggestions {
            position: absolute;
            top: 100%;
            left: 0;
            background: $white;
            padding: 10px 0;
            min-width: 120px;
            @include border-radius($border-radius-lg);
            @include box-shadow(0 0.5rem 1rem rgba($black, .175));
            & ul {
                list-style-type: none;
                margin: 0;
                padding: 0;
                & li {
                    display: block;
                    padding: 5px 10px;
                    &:hover,
                    &:focus {
                        background: lighten($dark, 75%);
                    }
                    & span {
                        font-weight: 600;
                        color: lighten($dark, 30%);
                        & mark {
                            background: lighten($yellow, 20%);
                            color: $dark;
                            padding: 0 2px;
                            @include border-radius($border-radius);
                        }
                    }
                }
            }
        }
    }
}


/* React Color */

.sketch-picker,
.chrome-picker {
    background: none !important;
    border: none !important;
    .dropdown-menu & {
        margin: -8px 0;
    }
    @include box-shadow(none !important);
    & input {
        border: 1px solid $border-color !important;
        @include transition(all .2s linear);
        @include box-shadow(none !important);
        @include border-radius($border-radius);
        &:focus {
            border-color: $input-focus-border-color !important;
            outline: none;
            @include box-shadow(0 0 0 0.2rem rgba($blue, .25) !important);
        }
    }
}


/* React Slider */

.rc-slider {
    & .rc-slider-rail {
        background: lighten($dark, 75%);
    }
    & .rc-slider-track {
        background: $form-component-active-bg;
    }
    & .rc-slider-dot {
        border-color: lighten($dark, 50%);
        &.rc-slider-dot-active {
            border-color: $form-component-active-bg;
        }
    }
    & .rc-slider-handle {
        border-color: $form-component-active-bg;
        &:focus,
        &:active {
            @include box-shadow(0 0 0 3px rgba($form-component-active-bg, .25));
        }
    }
}

.rc-slider-tooltip {
    & .rc-slider-tooltip-content {
        margin-top: 10px;
        & .rc-slider-tooltip-inner {
            background: rgba($dark, .85);
            font-size: 11px;
            font-weight: 600;
            padding: 0 10px;
            line-height: 24px;
            @include box-shadow(none);
        }
    }
    &.rc-slider-tooltip-placement-top {
        & .rc-slider-tooltip-arrow {
            border-top-color: rgba($dark, .85);
        }
    }
}


/* React Table */

.ReactTable {
    font-size: $_RT_FONT_SIZE;
    font-family: $_RT_FONT_FAMILY;
    border: none;
    font-weight: bolder;
    .table-responsive {
        overflow: visible;
    }
    .dropdown-menu.show {
        left: -100% !important;
        right: 0 !important;
        min-width: 220px;
    }
    span {
        font-style: $_RT_FONT_FAMILY;
    }
    & .rt-export-div {
        button {
            margin: 5px 5px 5px 0px;
        }
    }
    & .rt-tablehead-div {
        height: 55px;
        input#tableFilterSearch {
            height: calc(1.8em + 1rem + 2px);
            padding: .5rem 1rem;
            font-size: .875rem;
            line-height: 1.8;
            border-radius: 8px;
        }
        select#tablePagingSelector {
            max-width: 110px;
            border: 1px solid $_RT_INPUT_BORDER_COLOR;
        }
    }
    & .rt-pagination-div {
        & .pagination-btn {
            background-color: $_RT_PAGINATION_BTN_COLORS;
            border-color: #ffffff;
            height: 35px;
            min-width: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
            margin-bottom: 5px;
            line-height: 1.42857143;
            border: 1px solid #ddd;
            border-radius: 6px !important;
            i {
                color: $dark
            }
            &:hover {
                color: #242a30 !important;
                background: #f2f3f4 !important;
                border-color: #dadfe2 !important;
                transition: all 0s !important;
            }
            &:disabled {
                color: #6c757d !important;
                pointer-events: none;
                cursor: auto;
                background-color: #fff !important;
                border-color: #dee2e6 !important;
                i {
                    color: #6c757d !important;
                }
            }
            &.selected {
                background-color: lawngreen !important;
                color: white !important;
            }
        }
        & .pagination-page-details {
            float: right;
            &input#toPageInput {
                border: 1px solid $_RT_INPUT_BORDER_COLOR;
            }
        }
    }
    thead.rt-headergroup {
        text-align: center;
        background-color: $_RT_HEAD_BG_COLOR;
        th {
            color: $_RT_HEAD_FONT_COLOR;
            border-color: $_RT_HEAD_BG_COLOR;
        }
    }
    .col-nowrap td,
    .col-nowrap th {
        white-space: nowrap;
        padding: 10px 15px;
    }
}


/* Boxed Layout */

.boxed-layout {
    #root {
        height: 100%;
    }
}


/* Sweet Alert */

.sweet-alert {
    & .btn {
        @include box-shadow(none !important);
    }
}