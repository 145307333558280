.page-title {
    margin-bottom: 30px;
}

.btn-bottom-panel {
    margin-left: 1%;
    margin-top: 1%;
}

.btn-primary, .btn-primary:focus {
    color: white;
    background: #36b900;
    border-color: #36b900;
}

    .btn-primary:active, .btn-primary:hover {
        background-color: white !important;
        color: #36b900;
        border-color: #36b900 !important;
    }

.form-group-margin-horizontal {
    margin-right: 1.2rem;
    margin-left: 1.2rem;
}

.form-select {
    width: 190%;
    height: calc(1.5em + .875rem + 2px);
}

.custom-panel-body {
    padding-bottom: 1.3rem !important;
}

.package-table {
    width: 100%;
    text-align: center;
}

.reference-limit-table {
    width: 2100px;
    text-align: center;
}

    .package-table > thead > tr > th, .reference-limit-table > thead > tr > th {
        border-bottom-width: unset !important;
        border-bottom: unset !important;
    }

.package-input {
    text-align: center;
    vertical-align: middle
}

table.package-table td, table.package-table th {
    max-width: 100px !important;
    vertical-align: middle
}

table.reference-limit-table td, table.reference-limit-table th {
    max-width: 70px !important;
    vertical-align: middle
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.custom-tab-header > .nav-item, .fight-limit-tab-header > .nav-item {
    background-color: transparent;
    cursor: pointer;
    user-select: none;
}

    .custom-tab-header > .nav-item > a {
        font-size: 0.9rem;
    }

        .custom-tab-header > .nav-item > a.active {
            background-color: white;
            border-radius: 0;
            color: #307825 !important;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border: 0.5px solid gainsboro !important;
            border-bottom: none !important;
            font-weight: bolder !important;
        }

        .custom-tab-header > .nav-item:hover, .custom-tab-header > .nav-item:focus, .custom-tab-header > .nav-item > a:hover {
            background-color: #cacaca;
            cursor: pointer;
            user-select: none;
            color: #307825 !important;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }

.custom-tab-content {
    box-shadow: 2px 2px 10px #0000001f;
    border-right: 0.5px solid gainsboro !important;
    border-left: 0.5px solid gainsboro !important;
    border-bottom: none !important;
}

.fight-limit-tab-header {
    background-color: #f5f5f5;
    margin-top: 5px;
    margin-bottom: 5px;
}

    .fight-limit-tab-header > .nav-item > a {
        font-size: 0.9rem;
    }

        .fight-limit-tab-header > .nav-item > a.active {
            background-color: white;
            border-radius: 0;
            color: black !important;
            font-weight: bolder !important;
        }

        .fight-limit-tab-header > .nav-item > a:hover {
            color: #4abf13 !important;
        }

.td-red-label {
    color: crimson;
    width: 100%;
    margin-bottom: unset;
}

.custom-tab-content > .tab-pane > .tab-content {
    margin-bottom: 0;
}

.input-reference-mass-update, .input-reference {
    font-weight: bold;
    font-size: .85rem
}

    .input-reference-mass-update::placeholder,
    .input-reference::placeholder {
        font-weight: bold;
        opacity: 1; /* Firefox */
        font-size: .85rem
    }

    .input-reference-mass-update:-ms-input-placeholder,
    .input-reference:-ms-input-placeholder { /* Internet Explorer 10-11 */
        font-weight: bold;
        font-size: .83rem
    }

    .input-reference-mass-update::-ms-input-placeholder,
    .input-reference::-ms-input-placeholder { /* Microsoft Edge */
        font-weight: bold;
        font-size: .83rem
    }

.form-select-group-title {
    font-weight: bolder !important;
}

.custom-text-area {
    resize: none;
}

.react-table-row-clickable {
    cursor: pointer;
}

.btn-default, .btn-default:hover, .btn-default:focus, .btn-default:active {
    background-image: linear-gradient(180deg, #DAEAFA, #BED5EB,#6790BB,#5682B1);
    color: white !important;
    border: none !important;
    outline: none;
    border-radius: 24px;
    box-shadow: 0px 8px 13px #B3CCE4D6;
}

.btn-success {
    border: none !important;
}

.inline-input-row {
    position: relative;
}

.input-inner-button {
    position: absolute;
    right: 0;
    height: calc(1.5em + .875rem + 2px);
    width: 28%;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
}

.panel > .panel-header-warning {
    background-image: linear-gradient(180deg, #FFD045, #FFAE00) !important;
}

.panel > .panel-header-primary {
    background-image: linear-gradient(180deg, #4F8EF8, #1348E2) !important;
}

.panel > .panel-title-center {
    text-align: center
}

.panel > .panel-title-left {
    text-align: left
}

.panel > .panel-title-right {
    text-align: right
}

.custom-number-meaning-panel > .panel > .panel-body {
    text-align: center
}

.image-center-warning {
    border: 2px solid #FFBC1E;
    width: 170px;
    height: 170px;
    outline: none;
    border-radius: 15px;
}

.number-meaning-container {
    position: relative;
    padding: 30px 0 15px 0;
    margin: 0 auto;
    width: 100%;
    display: inline-flex;
    justify-content: space-evenly
}

    .number-meaning-container > label:first-of-type {
        font-size: 1.2rem !important;
    }

    .number-meaning-container > label:last-of-type {
        font-size: 1.2rem !important;
    }

.image-center-primary {
    border: 2px solid #2158E7;
    width: 170px;
    height: 170px;
    outline: none;
    border-radius: 15px;
}

.label-number-yellow {
    color: #FFBC1E;
    font-weight: bolder !important;
}

.label-number-blue {
    color: #2158E7;
    font-weight: bolder !important;
}

.custom-option-wrapper {
    cursor: pointer;
    margin-top: 0 !important;
    padding: 5px 8px !important;
    height: 100% !important;
    position: relative;
}

    .custom-option-wrapper > label {
        padding: 0;
        margin: 0
    }

    .custom-option-wrapper:hover {
        background-color: #daf6ff;
    }

    .custom-option-wrapper:last-of-type:hover {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .custom-option-wrapper:first-of-type:hover {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

textarea.without-border:focus, textarea.without-border:active, textarea.without-border {
    border: none !important;
    outline: none !important;
    height: 100%;
    width: 100%;
    font-size: 1.1rem;
}

.express-betting-panel > .panel-body {
    padding: 8px 12px;
}

.express-betting-notice-panel > .panel-body {
    padding: 0;
    background-color: #00CC4A0F;
    border-radius: 15px;
    overflow: hidden;
}

.expandable-title-header > div > label {
    padding: 0;
    margin: 0 !important;
}

.expandable-title-header > div > span > i {
    align-self: center;
    vertical-align: middle;
    display: table-cell;
}

.expandable-title-header > div > span {
    justify-content: center;
    align-items: center;
    text-align: center;
    display: table;
}

.dot {
    height: 25px;
    width: 25px;
    background-color: transparent;
    border-radius: 50%;
    display: inline-block;
    background-image: none !important;
    border: 0.3px solid gray;
}

.expandable-title-header > div > span.selected {
    background-image: linear-gradient(0deg, #0CC155, #0DCA4B, #5CE50B, #6CF408) !important;
    box-shadow: 0px 8px 13px #1DCD1660;
    background: no-repeat center center fixed;
    border: none;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

    .expandable-title-header > div > span.selected > i {
        color: white;
    }

.expandable-title-header {
    padding: 10px 25px;
    position: relative;
    background-color: white;
    box-shadow: 0px 1px 1px #d5d5d5;
}

    .expandable-title-header:first-of-type {
        border-bottom: 0.1px solid #d5d5d5;
    }

.fa-arrow-down-animated {
    animation-name: collapse-icon-animation;
    animation-duration: 0.25s;
    animation-direction: normal;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

.fa-arrow-up-animated {
    animation-name: collapse-icon-animation-reverse;
    animation-duration: 0.25s;
    animation-direction: normal;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes collapse-icon-animation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(180deg);
    }
}

@keyframes collapse-icon-animation-reverse {
    0% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.selected-wrapper {
    padding: 10px 20px;
    border-left: 2px solid #5CC85C;
}

    .selected-wrapper > p {
        margin-bottom: 0 !important;
        font-size: 0.95rem;
    }

.express-betting-notice-img-wrapper {
    background-image: linear-gradient(0deg, #484F69, #21234D) !important;
    display: inline-flex;
    align-items: center;
    width: 100%;
}

    .express-betting-notice-img-wrapper > div {
        margin: 0px 15px;
    }

        .express-betting-notice-img-wrapper > div:first-of-type {
            flex: 0.4
        }

        .express-betting-notice-img-wrapper > div:last-of-type {
            flex: 0.6
        }

            .express-betting-notice-img-wrapper > div:last-of-type > h3 {
                margin-top: 20px;
            }

            .express-betting-notice-img-wrapper > div:last-of-type > label {
                margin-bottom: 20px;
            }

        .express-betting-notice-img-wrapper > div > label,
        .express-betting-notice-img-wrapper > div > h3 {
            color: white;
        }

@media (max-width: 1150px) {
    .package-table {
        width: 1300px;
        text-align: center;
    }
}

@media (max-width: 767.98px) {
    .input-inner-button {
        width: 50%;
    }
}

@media (min-width: 2050px) {
    .input-inner-button {
        width: 150px;
    }
}

.receipt-panel > .panel-body > label {
    margin-bottom: unset !important;
}

.receipt-panel-header > .panel-title {
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

#stake-holder-table > tfoot > tr:last-of-type {
    display: none;
}

.column-right {
    text-align: right;
}
